import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import NavbarStyleFive from "../components/_App/NavbarStyleFive";

import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import FeedbackStyleThree from '../components/Common/FeedbackStyleThree';
import Partner from '../components/MachineLearning/Partner';

import AboutImg from "../assets/images/agency-image/yerindetakas-hakkimizda.png";

// Shape Images
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";

const About3 = () => (
    <Layout>
        <SEO title="About Us Page 3" />

        <Navbar />

        <div className="page-title-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <h2>HAKKIMIZDA</h2>


                        <div className="agency-about-area ptb-80">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="agency-about-img">
                                            <img src={AboutImg} alt="image" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <div className="agency-about-content">
                                            <span className="sub-title"></span>
                                            <h2>Hoşgeldiniz!</h2>


                                            <h5>Ürününü Seç </h5> 
                        <h5>Whatsapp Üzerinden Teklifini Ver</h5> 
                        <h5>Teklifiniz Onaylanınca </h5> 
                        <h5>Konya İçi Adresinize Gelsin</h5> 
                        <h5>Takasınız Yerinde Olsun</h5> 
                        <h5>İhtiyacınıza 0.00 ₺ ye Sahip Olun </h5> 

                                            <p>Konya'nın kalbinde, değerli kullanıcılarımıza özel bir takas platformuyla karşınızdayız.
                                                Sizlere sıfır veya ikinci el ürünlerinizi takas edebileceğiniz, ihtiyacınız olan ürünleri bulabileceğiniz bir ortam sunuyoruz.</p>

                                            <p>Yerinde Takas, Değer Katmak İçin Var!</p>

                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="about-inner-area">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                            <div className="about-text">
                                                <h3>Ekonomik Değişim</h3>
                                                <p>Ekonomik ve çevresel açıdan dost bir alternatif olan takas, sizlere sıfır maliyetle ürünlerinizi el değiştirmenin keyfini yaşatır.</p>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                            <div className="about-text">
                                                <h3>Çeşitlilik</h3>
                                                <p>Her kategoriye özel geniş bir ürün yelpazesiyle, ihtiyacınıza uygun ürünleri bulmanız artık çok daha kolay!</p>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                            <div className="about-text">
                                                <h3>Güvenilirlik</h3>
                                                <p>Konya'nın güvenilir takas platformu olarak, kullanıcılarımızın güvenliği ve memnuniyeti her zaman önceliğimizdir.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </div>

        {/* <Team />

        <FeedbackStyleThree />
        
        <FunFactsArea />

        <div className="pb-80">
            <Partner />
        </div> */}

        <Footer />
    </Layout>
)

export default About3;